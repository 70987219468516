.head {
  background-color: #eeeeee;
  padding-bottom: 0.05rem;
}

.head /deep/ .breadcrumb {
  border-bottom: 0;
  margin-bottom: 0.05rem;
}

.head /deep/ .ant-input {
  background-color: #fff;
}

.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0.8rem;
  font-weight: 600;
  font-size: 0.2rem;
  color: #333333;
}

.summary .keyword {
  flex: 1;
}

.summary .keyword .red {
  color: red;
}

.summary .result {
  flex-shrink: 0;
}

.list {
  padding-bottom: 0.9rem;
}

.list /deep/ .ant-list-item {
  border-bottom: 0 !important;
  margin-bottom: 0.2rem;
}

.list /deep/ .item {
  flex: 1;
}

.list /deep/ .item h2:hover span {
  color: #f6a75c !important;
}

.list /deep/ .pagenation {
  margin-top: 0.5rem;
}